<template>
  <div id="app">
    <keep-alive>
    <router-view :key="$route.fullPath" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view :key="$route.fullPath" v-if="!$route.meta.keepAlive" />
  </div>
</template>
 
<script>
export default {
  name: "app",
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    }
  }
};
</script>
 
<style>
.el-menu--popup{ min-width: 120px !important;}
#app {
  padding: 0px;
  margin: 0px;
  /* font-family: Noto Serif SC; 思源宋体*/
  font-family: Microsoft YaHei;
  font-style: normal;
  font-weight: 400;
  cursor: Default;
  /* background: white !important; */
}
.norecord {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin-top: 200px;
}
.el-pager li.active {
  color: black;
  cursor: pointer;
}
.el-button--primary {
  background-color: #eb4f39;
  border-color: #eb4f39;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #eb4f39;
  background: #eb4f39;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #eb4f39;
}
.el-pager li:hover{
  color: #000;
}
.el-pagination button:hover{
  color: #000;
}
.vjs-custom-skin > .video-js .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  border-radius: 50%;
  height: 2em !important;
  line-height: 2em !important;
  margin-top: -1em !important;
  margin-left: -1em !important;
  width: 2em !important;
  outline: none;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-js:hover .vjs-big-play-button,
.vjs-custom-skin > .video-js .vjs-big-play-button:active,
.vjs-custom-skin > .video-js .vjs-big-play-button:focus {
  background-color: rgba(255, 255, 255, 0.4) !important;
}
 .message-logout{
  width: 330px !important;
	height: 190px !important;
	background-color: #ffffff;
	box-shadow: 0px 0px -1px 3px 
		rgba(221, 221, 221, 0.95);
	border-radius: 10px;
  z-index: 3000;
  padding: 0;
}
.message-logout .el-message-box__content{
  
    display: flex;
    align-items: center;
    /* margin: -3px  auto auto; */
    /* margin: 0 auto; */
    margin-left: 26px;
    padding: 0;
     margin-top: 10px;
}
.message-logout .el-input__inner{
width: 230px;
	height: 40px;
	border-radius: 5px;
	border: solid 1px #dddddd;
}
.message-logout .el-message-box__message p{
	
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 2px;
	color: #000000;
}
 .message-logout .el-button--primary {
   width: 80px;
	height: 35px;
  line-height: 35px;
	background-color: #ff9234;
	border-radius: 5px;
	
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	color: #ffffff !important;
  padding: 0;
}
  .message-logout .el-button--small{
    border: none;
	
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 24px;
	letter-spacing: 2px;
	color: #ff9234;

}
.message-logout .el-message-box__btns{
padding: 21px 28px 0;
}

</style>