import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/index.css'
import './assets/icon/iconfont.css'
import App from './App.vue'
import router from './router'
import scroll from 'vue-seamless-scroll'
import './css/font.css'
import qs from 'qs';
import {
  Pagination,
  Input,
  Button,
  Menu,
  MenuItem,
  Carousel,
  Submenu,
  Popover,
  Backtop,
  CarouselItem,
  Icon,
  Select,
  Option,
  Form,
  FormItem,
  Radio,
  RadioGroup,
  Collapse,
  CollapseItem,
  Table,
  TableColumn,
  Tabs,
  TabPane,
  DatePicker,
  Checkbox,
  CheckboxGroup,
  MessageBox,
  Message
} from 'element-ui';
Vue.use(Pagination);
Vue.use(Input);
Vue.use(Button);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Carousel);
Vue.use(Submenu);
Vue.use(Popover);
Vue.use(Backtop);
Vue.use(CarouselItem);
Vue.use(Icon);
Vue.use(Select);
Vue.use(Option);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Radio);
Vue.use(Collapse);
Vue.use(RadioGroup);
Vue.use(CollapseItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(DatePicker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup)
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.prototype.$qs = qs
Vue.config.productionTip = false
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import axios from 'axios'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)
Vue.prototype.$axios = axios;
Vue.use(VideoPlayer)
Vue.use(scroll)
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.config.productionTip = false
axios.interceptors.request.use(config => {
  if (localStorage.getItem("tokenlogin"))
    config.headers.Authorization = localStorage.getItem("tokenlogin")
  return config
}, error => {
  return Promise.reject(error)
})

// // 响应拦截  500 token过期处理
// axios.interceptors.response.use(response => {
//   let code = response.data.code
//   if (code == 505) {
//     // alert('登录超时或被禁用,请重新登录');
//     localStorage.removeItem('tokenlogin')
//     // 页面跳转
//     setTimeout(() => {
//       router.push({
//         path: "/accountlogin"
//       });
//     }, 1000);
//   }
//   return response
// }, error => {
//   return Promise.reject(error)
// })
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {

  }
  window.scrollTo(0, 0)
  next()
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')