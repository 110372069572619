import {
    Footer
} from 'element-ui'
import Vue from 'vue'
import Router from 'vue-router'
const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)
export default new Router({
    mode: 'history',
    routes: [{
            path: '/404',
            name: '404',
            components: {
                default: () => import('./views/404')
            },
            meta: {
                title: '404页面'
            }
        },
        {
            path: '/register',
            name: 'Register',
            components: {
                default: () => import('./views/Register')
            },
            meta: {
                title: '注册'
            }
        },
        {
            path: '/PersonalCenter',
            name: 'PersonalCenter',
            components: {
                default: () => import('./views/PersonalCenter')
            },
            meta: {
                title: '个人中心',
                keepAlive: false,
            }
        },
        {
            path: '/accountlogin',
            name: 'AccountLogin',
            components: {
                default: () => import('./views/AccountLogin')
            },
            meta: {
                title: '账号登录',
                keepAlive: false,
            }
        },
        {
            path: '/ForgetPassword',
            name: 'ForgetPassword',
            components: {
                default: () => import('./views/ForgetPassword')
            },
            meta: {
                title: '忘记密码'
            }
        },
        {
            path: '/',
            name: 'Home',
            components: {
                default: () => import('./views/Home')
            },
            meta: {
                title: '首页',
                keepAlive: false,
            }
        },
        {
            path: '/list-text',
            name: 'ListText',
            components: {
                default: () => import('./views/ListText')
            },
            meta: {
                title: '文本列表页',
                keepAlive: true,
            }
        },
        {
            path: '/list-img',
            name: 'ListImg',
            components: {
                default: () => import('./views/ListImg')
            },
            meta: {
                title: '图文列表页',
                keepAlive: true,
            }
        },
        {
            path: '/detail',
            name: 'Detail',
            components: {
                default: () => import('./views/Detail')
            },
            meta: {
                title: '详情页',
                keepAlive: true,
            }
        },
        {
            path: '/detailvedio',
            name: 'Detailvedio',
            components: {
                default: () => import('./views/Detailvedio')
            },
            meta: {
                title: '视频详情页',
                keepAlive: true,
            }
        },
        {
            path: '/Aggregateretrieval',
            name: 'Aggregateretrieval',
            components: {
                default: () => import('./views/Aggregateretrieval')
            },
            meta: {
                title: '聚合检索',
                keepAlive: true,
            }
        },
        {
            path: '/StationSearch',
            name: 'StationSearch',
            components: {
                default: () => import('./views/StationSearch.vue')
            },
            meta: {
                title: '站内搜索',
                keepAlive: true,
            }
        },
        {
            path: '/CategoryBrowsing',
            name: 'CategoryBrowsing',
            components: {
                default: () => import('./views/CategoryBrowsing.vue')
            },
            meta: {
                title: '分类浏览',
                keepAlive: true,
            }
        },
        {
            path: '/Retrievaldetails',
            name: 'Retrievaldetails',
            components: {
                default: () => import('./views/Retrievaldetails.vue')
            },
            meta: {
                title: '检索详情',
                keepAlive: true,
            }
        },
        {
            path: '/HotCommend',
            name: 'HotCommend',
            components: {
                default: () => import('./views/HotCommend.vue')
            },
            meta: {
                title: '热门推荐',
                keepAlive: true,
            }

        }, {
            path: '/NewBook',
            name: 'NewBook',
            components: {
                default: () => import('./views/NewBook.vue')
            },
            meta: {
                title: '新书通报',
                keepAlive: true,
            }
        },
        {
            path: '/Retrievalpersonal',
            name: 'Retrievalpersonal',
            components: {
                default: () => import('./views/Retrievalpersonal.vue')
            },
            meta: {
                title: '个人中心',
                keepAlive: false,
            }
        },
        {
            path: '/Retrievalsetting',
            name: 'Retrievalsetting',
            components: {
                default: () => import('./views/Retrievalsetting.vue')
            },
            meta: {
                title: '个人设置',
                keepAlive: false,
            }
        },
        {
            path: '/weChatLogin',
            name: 'weChatLogin',
            components: {
                default: () => import('./views/weChatLogin.vue')
            },
            meta: {
                title: '微信登录',
                keepAlive: false,
            }
        },

    ]
})